<app-dropdown class="user-menu">
    <ng-container dropdown-button>
        <img
            [src]="user.picture || 'assets/img/default-profile.png'"
            class="user-image img-circle elevation-2"
            alt="User Image"
        />
    </ng-container>
    <ng-container dropdown-menu>
        <li class="user-header bg-primary">
            <img
                [src]="user.picture || 'assets/img/default-profile.png'"
                class="img-circle elevation-2"
                alt="User Image"
            />

            <p>
                <span>{{ user.email }}</span>
                
            </p>
        </li>
        <!-- Menu Body -->
        <li class="user-body">
           
        </li>
        <!-- Menu Footer-->
        <li class="user-footer">
           
            <a (click)="logout()" class="btn btn-default btn-flat float-right"
                >Disconnetti</a
            >
        </li>
    </ng-container>
</app-dropdown>
