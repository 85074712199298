<div class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-6">
                <h1 class="m-0 text-dark">Gestione libri</h1>
            </div>
            
            <!-- /.col -->
        </div>
        <!-- /.row -->
    </div>
    <!-- /.container-fluid -->
</div>
<!-- /.content-header -->

<!-- Main content -->
<section class="content">
    <div class="container-fluid">
        <!-- Small boxes (Stat box) -->
        <div class="row">
            <div class="col-12 text-right mb-2">
                <button class="btn btn-xs btn-info mr-2" data-toggle="modal" data-target="#bookModal" (click)="addBook();"><i class="fa fa-plus"></i>&nbsp;Aggiungi</button>
                <button class="btn btn-xs btn-success"  (click)="save();"><i class="fa fa-save"></i>&nbsp;Salva lista</button>
      
            </div>
            <div class="col-12">
                <table class="table table-sm">
                    <thead>
                        <th>ISBN</th>
                        <th>Immagine</th>
                        <th>Titolo</th>
                        <th>Abilitato</th>
                        <th></th>
                    </thead>
                    <tbody>
                        <tr *ngFor="let b of list">
                            <td>{{b.isbn}}</td>
                            <td><img height="32" src="{{URL}}/books/covers/{{b.cover}}"></td>
                            <td>{{b.title}}</td>
                            <td>
                                <i class="text-success fa fa-check" *ngIf="b.enabled" (click)="b.enabled=0"></i>
                                <i class="text-warning fa fa-times" *ngIf="!b.enabled" (click)="b.enabled=1"></i>
                            </td>
                            <td>
                                <button  class="btn btn-info btn-xs mr-2" data-toggle="modal" data-target="#bookModal" (click)="openBook(b);" ><i class="fa fa-pencil-alt"></i></button>
                                <button  class="btn btn-danger btn-xs"  (click)="deleteBook(b);" ><i class="fa fa-trash"></i></button>
                            
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <!-- ./col -->
           
        </div>
        <!-- /.row -->
    </div>
    <!-- Main row -->
</section>
<!-- /.content -->


<div id="bookModal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="bookModalLabel" aria-hidden="true" >
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                Dettaglio libro
            </div>
            <div class="modal-body" *ngIf="bookSelected">
                <div class="row">
                    <div class="col-3">
                        <img style="width:100%;" src="{{URL}}/books/covers/{{bookSelected.cover}}" />
                        <input *ngIf="bookSelected.isbn" class="form-control" type="file"  (change)="onChange($event)">
                    </div>
                    <div class="col-9">
                        <div class="form-group row">
                            <div class="col-2">ISBN</div>
                            <div class="col-10">
                                <input type="text" class="form-control form-control-sm" name="isbn" [(ngModel)]="bookSelected['isbn']">
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-2">Titolo</div>
                            <div class="col-10">
                                <input type="text" class="form-control form-control-sm" name="title" [(ngModel)]="bookSelected['title']">
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-2">Abilitato</div>
                            <div class="col-10">
                                <select  class="form-control form-control-sm" name="enabled" [(ngModel)]="bookSelected.enabled">
                                    <option value="0">No</option>
                                    <option value="1">Si</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
                
                
         
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-success" data-dismiss="modal">Ok</button>
                
            </div>
        </div>
    </div>
</div>