import { OnInit } from '@angular/core';
import { AppComponent } from '@/app.component';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {Component} from '@angular/core';
import { ApiService } from '@services/api.service';
import {ToastrService} from 'ngx-toastr';


@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {


    get URL(){
        return AppComponent.URL_SERVER;
    }
    list:any;
    bookSelected:any;
    file: File = null; // Variable to store file

    constructor(
        private http:HttpClient,
        private apiService: ApiService,
        private toastService:ToastrService
       
        
        ){

    }

    ngOnInit(){
        this.getList();
    }

    getList(){
        this.apiService.downloadList((items)=>{
            this.list=items;
        })
    }
    
    openBook(b){
        this.bookSelected=b;
       

    }

    addBook(){
        this.bookSelected={};
        this.bookSelected['isbn']="";
        this.bookSelected['title']="";
        this.bookSelected['enabled']=0;
        this.bookSelected['cover']="";
        this.list.push(this.bookSelected);

    }
    deleteBook(b){
        if(confirm("Sicuro di voler eliminare il libro dalla lista?")){
            for(let i=0;i<this.list.length;i++){
                if(this.list[i]==b){
                    this.list.splice(i,1);
                    return;
                }
            }
        }
    }

     // On file Select
     onChange(event) {
        this.file = event.target.files[0];
        this.onUpload();
    }

    onUpload(){
        this.apiService.upload(this.file,this.bookSelected.isbn).subscribe(
            (event: any) => {
                
                if (typeof (event) === 'object') {
                    this.bookSelected['cover'] = event.link;
                   
  
                    
                }
            }
        );

    }


    save(){
        this.apiService.save(this.list).subscribe((result)=>{
            this.toastService.show("Salvataggio completato","Salvataggio");
        })
    }

}
