import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppComponent } from '@/app.component';

@Injectable({
    providedIn: 'root'
})
export class ApiService {
    constructor(private http: HttpClient) {}

    // Returns an observable
    upload(file,isbn):Observable<any> {
    
        // Create form data
        const formData = new FormData(); 
        
        // Store form name as "file" with file data
        formData.append("fileToUpload", file, file.name);
        
        // Make http post request over api
        // with formData as req
        return this.http.post(AppComponent.URL_SERVER+"api.php?task=uploadCover&isbn="+isbn, formData)
    }

    // Returns an observable
    save(list):Observable<any> {
    
        // Create form data
        const formData = new FormData(); 
        
        // Store form name as "file" with file data
        formData.append("list",JSON.stringify(list));
        
        // Make http post request over api
        // with formData as req
        return this.http.post(AppComponent.URL_SERVER+"api.php?task=saveList", formData)
    }

    downloadList(onComplete){
        let httpOptions = {
            headers: new HttpHeaders({
              'Content-Type': 'application/json',
            })
        };
        this.http.get(AppComponent.URL_SERVER+"api.php?task=getList",httpOptions).subscribe((items)=>{
            if(onComplete)
                onComplete(items);
        });
    }

}
